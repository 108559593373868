:root {
  --primary-bg-color: #F4F6F6; /*1*/
  --secondary-bg-color: #313A3E; /*5*/
  --primary-text-color: #313A3E;/*5*/
  --secondary-text-color: #F4F6F6;/*1 E3F2FD*/
  --accent-color: #D1913D;/*3*/
}

/* Custom Backgrounds */
.bg-custom-light {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-text-color);
}

.bg-custom-light strong{
  color: #297F89;/*3* #141B41*/
}


.bg-custom-dark {
  background-color: var(--secondary-bg-color) !important;
  color: var(--secondary-text-color);
}

.bg-custom-dark strong{
  color: #a3ff99;/*2*/
}


.bg-custom-label:hover{
  color: var(--accent-color);
}

.bg-custom-label:active{
  color: var(--accent-color);
}
.bg-custom-label:focus{
  color: var(--accent-color);
}


.bg-custom-burg{
  color: var(--secondary-bg-color);
}

.bg-custom-burg:focus{
  color: var(--secondary-bg-color);
}

/* Custom Buttons */
.btn-custom {
  background-color: var(--accent-color) !important;
  color: var(--primary-text-color);
  border: none;
}

.btn-custom:hover {
  background-color: #FFB14B; /* Darker shade for hover */
}

/* Custom Text Colors */
.text-custom-dark {
  color: var(--primary-text-color) !important;
}

.text-custom-light {
  color: var(--secondary-text-color) !important;
}

